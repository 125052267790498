import { useAuth } from "../../auth";
import { gql, useQuery } from "@apollo/client";
import { clamp } from "lodash";
import {
  Spinner,
  P,
  ProgressBar,
  ButtonLink,
} from "../../ui";

const ACCOUNT_PLAN_QUERY = gql`
  query AccountPlan($accountId: ID) {
    getAccount {
      id
      name
      plan
      hasBilling
      sendCount
      totalTeamMembers
    }
    listRecipients(accountId: $accountId) {
      data {
        id
        email
        status
        verifiedAt
        createdAt
        updatedAt
      }
    }
    listTemplates(accountId: $accountId) {
      data {
        name
        id
        updatedAt
        shareId
      }
    }
  }
`;

export const Plans = {
  FREE: {
    cost: "$0",
    templates: 5,
    recipients: 2,
    members: 1,
    quota: 20,
  },
  PRO: {
    cost: "$9",
    templates: 20,
    recipients: 5,
    members: 1,
    quota: 50,
  },
  TEAM: {
    cost: "$49",
    templates: 100,
    recipients: 20,
    members: 3,
    quota: 100,
  },
  // TODO add enterprise
};

const AccountPlan = () => {
  const { user } = useAuth();
  const { loading, data } = useQuery(ACCOUNT_PLAN_QUERY, {
    ssr: false,
    skip: !user,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const isLoading = !data?.listTemplates?.data && (!user || loading);
  const account = data?.getAccount || {};
  const templates = data?.listTemplates?.data || [];
  const recipients = data?.listRecipients?.data || [];
  const sendCount = account?.sendCount || 0;
  const plan = account?.plan || "FREE";
  const previewProgressBar = clamp(
    (sendCount / Plans[plan].quota) * 100,
    0,
    100
  );
  const previewDescription = `${sendCount}/${Plans[plan].quota}`;
  const templateProgressBar = clamp(
    (templates.length / Plans[plan].templates) * 100,
    0,
    100
  );
  const templateDescription = `${templates.length}/${Plans[plan].templates}`;
  const recipientProgressBar = clamp(
    (recipients.length / Plans[plan].recipients) * 100,
    0,
    100
  );

  const userAccountProgressBar = clamp(
    (account?.totalTeamMembers / Plans[plan].members) * 100,
    0,
    100
  );

  const recipientDescription = `${recipients.length}/${Plans[plan].recipients}`;
  const userAccountInfo = `${account?.totalTeamMembers}/${Plans[plan].members}`;

  return (
    <div className="space-y-8 mt-6 mb-8 px-4 sm:px-6 md:px-0">
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Plans & Pricing
            </h3>
            {/* <p className="mt-1 text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p> */}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="space-y-8">
              <div className="grid grid-cols-3 gap-6">
                {isLoading ? (
                  <Spinner className="h-12 w-12" />
                ) : (
                  <div className="col-span-3 xl:col-span-2">
                    <h4 className="text-base font-bold text-gray-900 mb-6">
                      Current Plan: {plan} ({Plans[plan].cost}/mth)
                    </h4>
                    <div className="my-6">
                      <p className="text-sm font-medium text-gray-700">
                        Test Emails Sent Today
                      </p>
                      <ProgressBar value={previewProgressBar} />
                      <P>{previewDescription} sent (resets midnight PST)</P>
                    </div>
                    <div className="my-6">
                      <p className="text-sm font-medium text-gray-700">
                        Saved Templates
                      </p>
                      <ProgressBar value={templateProgressBar} />
                      <P>{templateDescription} used</P>
                    </div>

                    <div className="mt-6">
                      <p className="text-sm font-medium text-gray-700">
                        Verified Recipients
                      </p>
                      <ProgressBar value={recipientProgressBar} />
                      <P>{recipientDescription} used</P>
                    </div>

                    <div className="mt-6">
                      <p className="text-sm font-medium text-gray-700">
                        User Accounts
                      </p>
                      <ProgressBar value={userAccountProgressBar} />
                      <P>{userAccountInfo} used</P>
                    </div>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 xl:col-span-2 pt-4 border-t border-gray-200">
                  <div className="flex justify-start pt-4">
                    <ButtonLink href={`/plans`}>Upgrade Plan</ButtonLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPlan;
